<template>
    <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="750px" >
        <v-card>
             <v-card-title>{{$t('adminlog.dialog')}}</v-card-title>
             <v-divider></v-divider>
<!-- File -->
             <v-card-text v-if="detaildata.process_type === '0'">
               <v-list two-line subheader>
                 <!-- <v-subheader><v-divider class="mx-4" ></v-divider></v-subheader>
                <v-list-item-icon style="padding-left: 4%">
            <v-icon x-large  style="font-weight: 100"></v-icon>
         </v-list-item-icon> -->
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.filename')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.current_filename !== ''">{{detaildata.current_filename}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>      
      </v-list-item>  
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.foldername')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.current_foldername !== ''">{{detaildata.current_foldername}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>       
      </v-list-item>           
          <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.status')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.current_status !== ''">{{detaildata.current_status}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>{{$t('adminlog.newfilename')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.new_filename !== ''">{{detaildata.new_filename}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.newfilestatus')}}</v-list-item-title>
          <v-list-item-subtitle  v-if="detaildata.new_filestatus !== ''">{{detaildata.new_filestatus}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                        <!-- <v-list-item-subtitle v-else>{{ filedata.file_owner_eng}}</v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
          <v-list-item >
            <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.newfoldername')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.new_foldername !== ''">{{detaildata.new_foldername}}</v-list-item-subtitle>
           <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
        <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-file-clock</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.oldfilename')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.old_filename !== ''">{{detaildata.old_filename}}</v-list-item-subtitle>
           <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
        <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.oldfilestatus')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.old_filestatus !== ''">{{detaildata.old_filestatus}}</v-list-item-subtitle>
           <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
        <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-folder-clock</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.oldfoldername')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.old_foldername !== ''">{{detaildata.old_foldername}}</v-list-item-subtitle>
           <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </v-list>
             </v-card-text>
<!-- Folder -->
            <v-card-text v-else-if="detaildata.process_type === '1'">
              <v-list two-line subheader>
                 <!-- <v-subheader><v-divider class="mx-4" ></v-divider></v-subheader>
                <v-list-item-icon style="padding-left: 4%">
            <v-icon x-large  style="font-weight: 100"></v-icon>
         </v-list-item-icon> -->
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.foldername')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.current_foldername !== ''">{{detaildata.current_foldername}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>  
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.status')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.current_status !== ''">{{detaildata.current_status}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>           
          <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-folder-edit</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.newfoldername')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.new_foldername !== ''">{{detaildata.new_foldername}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-folder-edit</v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>{{$t('adminlog.newfolderstatus')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.new_folderstatus !== ''">{{detaildata.new_folderstatus}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item >
            <v-list-item-icon>
            <v-icon>mdi-folder-clock</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.oldfoldername')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.old_foldername !== ''">{{detaildata.old_foldername}}</v-list-item-subtitle>
           <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
          </v-list-item>
          <v-list-item >
            <v-list-item-icon>
            <v-icon>mdi-folder-clock</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.oldfolderstatus')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.old_folderstatus !== ''">{{detaildata.old_folderstatus}}</v-list-item-subtitle>
           <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
          </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-folder-network</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.newparentfoldername')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.new_parent_folder_name !== ''">{{detaildata.new_parent_folder_name}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                        <!-- <v-list-item-subtitle v-else>{{ filedata.file_owner_eng}}</v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
      <v-list-item >
            <v-list-item-icon>
            <v-icon>mdi-folder-network</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.oldparentfoldername')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.old_parent_folder_name !== ''">{{detaildata.old_parent_folder_name}}</v-list-item-subtitle>
           <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
          
          
      </v-list>
            </v-card-text>
<!-- share -->
            <v-card-text v-else-if="detaildata.process_type === '3'">
              <v-list two-line subheader>
                 <!-- <v-subheader><v-divider class="mx-4" ></v-divider></v-subheader>
                <v-list-item-icon style="padding-left: 4%">
            <v-icon x-large  style="font-weight: 100"></v-icon>
         </v-list-item-icon> -->
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-folder-account</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.currentname')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.current_name !== ''">{{detaildata.current_name}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>  
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-share-circle</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.statussharelink')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.current_status_share_link !== ''">{{detaildata.current_status_share_link}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>           
          <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted-type</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.type')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.data_type !== ''">{{detaildata.data_type}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-folder-account</v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>{{$t('adminlog.name')}}</v-list-item-title>
        <v-list-item-subtitle v-if="detaildata.name !== ''">{{detaildata.name}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-folder-account</v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>{{$t('adminlog.newname')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.new_name !== ''">{{detaildata.new_name}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-folder-clock</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.oldname')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.old_name !== ''">{{detaildata.old_name}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                        <!-- <v-list-item-subtitle v-else>{{ filedata.file_owner_eng}}</v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
          <v-list-item >
            <v-list-item-icon>
            <v-icon>mdi-account-arrow-right</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.sharewith')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.share_with !== ''">{{detaildata.share_with}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
          </v-list-item>
          <v-list-item >
            <v-list-item-icon>
            <v-icon>mdi-share-circle</v-icon>
          </v-list-item-icon>
        <v-list-item-content >         
          <v-list-item-title>{{$t('adminlog.statusshare')}}</v-list-item-title>
           <v-list-item-subtitle v-if="detaildata.status_share !== ''">{{detaildata.status_share}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </v-list>
            </v-card-text>
<!--Account -->
            <v-card-text v-else-if="detaildata.process_type === '5'">
    <!-- ตั้งค่าการเข้าถึง -->
              <v-list two-line subheader v-if="detaildata.process_id !== 'other'">
                 <!-- <v-subheader><v-divider class="mx-4" ></v-divider></v-subheader>
                <v-list-item-icon style="padding-left: 4%">
            <v-icon x-large  style="font-weight: 100"></v-icon>
         </v-list-item-icon> -->
         <v-list-item>
           <v-list-item-icon>
            <v-icon>location_city</v-icon>
          </v-list-item-icon>
        <v-list-item-content v-if="$t('default') === 'th'">        
          <v-list-item-title>{{$t('adminlog.businessset')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.business_th_setting !== ''">{{detaildata.business_th_setting}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content> 
        <v-list-item-content v-else>        
          <v-list-item-title>{{$t('adminlog.businessset')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.business_en_setting !== ''">{{detaildata.business_en_setting}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>    
      </v-list-item>  
      <v-list-item>
           <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
        <v-list-item-content v-if="$t('default') === 'th'">        
          <v-list-item-title>ประเภท</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_name !== ''">{{detaildata.process_name}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content> 
        <v-list-item-content v-else>        
          <v-list-item-title>Type</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_name_eng !== ''">{{detaildata.process_name_eng}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>        
          <v-list-item-title>วันที่ตั้งค่า</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_dtm !== ''">{{formatdatetime(detaildata.process_dtm)}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>   
      </v-list-item>  
         <!-- <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.department')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.department_name !== ''">{{detaildata.department_name}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>            -->
          <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.nameset')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.name_th_setting !== ''">{{detaildata.name_th_setting}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>ประเภทข้อมูลที่ตั้งค่า</v-list-item-title>
          <v-list-item-content v-if="detaildata.data_type !== ''" style="color:rgba(0,0,0,.6);">{{detaildata.data_type}}</v-list-item-content>
         <v-list-item-subtitle v-else>-</v-list-item-subtitle> 
        </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>ชื่อข้อมูลที่ตั้งค่า</v-list-item-title>
          <v-list-item-content v-if="detaildata.data_name !== ''" style="color:rgba(0,0,0,.6);">{{detaildata.data_name}}</v-list-item-content>
         <v-list-item-subtitle v-else>-</v-list-item-subtitle> 
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>การตั้งค่าเดิม</v-list-item-title>
          <v-list-item-content v-if="detaildata.old_data_permission !== ''" style="color:rgba(0,0,0,.6);">{{fomatresultsetting_permission(detaildata.old_data_permission)}}</v-list-item-content>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-account-details-outline</v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>{{$t('adminlog.resultset')}}</v-list-item-title>
          <v-list-item-content v-if="detaildata.permission_status !== ''" style="color:rgba(0,0,0,.6);">{{fomatresultsetting_permission(detaildata.permission_status)}}</v-list-item-content>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>ประเภทการตั้งสิทธิ์</v-list-item-title>
          <v-list-item-content v-if="detaildata.permission_type !== ''" style="color:rgba(0,0,0,.6);">{{detaildata.permission_type}}</v-list-item-content>
         <v-list-item-subtitle v-else>-</v-list-item-subtitle> 
        </v-list-item-content>
      </v-list-item> 
      <v-list-item> 
        <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>การโคลนข้อมูล</v-list-item-title>
          <v-list-item-content v-if="detaildata.clone_data === 'True'" style="color:rgba(0,0,0,.6);">เปิด</v-list-item-content>
        <v-list-item-subtitle v-else>ปิด</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
              </v-list>
    <!-- อื่นๆ-->
              <v-list two-line subheader v-else>
                 <!-- <v-subheader><v-divider class="mx-4" ></v-divider></v-subheader>
                <v-list-item-icon style="padding-left: 4%">
            <v-icon x-large  style="font-weight: 100"></v-icon>
         </v-list-item-icon> -->
         <v-list-item>
           <v-list-item-icon>
            <v-icon>location_city</v-icon>
          </v-list-item-icon>
        <v-list-item-content v-if="$t('default') === 'th'">        
          <v-list-item-title>{{$t('adminlog.businessset')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.business_th_setting !== ''">{{detaildata.business_th_setting}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>        
          <v-list-item-title>{{$t('adminlog.businessset')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.business_en_setting !== ''">{{detaildata.business_en_setting}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>  
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.department')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.department_name !== ''">{{detaildata.department_name}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>        
          <v-list-item-title>วันที่ตั้งค่า</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_dtm !== ''">{{formatdatetime(detaildata.process_dtm)}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>           
          <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>
        <v-list-item-content  v-if="$t('default') === 'th'">
          <v-list-item-title>{{$t('adminlog.nameset')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.name_th_setting !== ''">{{detaildata.name_th_setting}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title >{{$t('adminlog.nameset')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.name_en_setting !== ''">{{detaildata.name_en_setting}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
            <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
      <v-list-item-content>        
          <v-list-item-title>ประเภท</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_name !== ''">{{detaildata.process_name}}</v-list-item-subtitle>
       <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content> 
        
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
            <v-icon>mdi-account-details-outline</v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>{{$t('adminlog.resultset')}}</v-list-item-title>
          <v-list-item-content v-if="detaildata.result_setting !== ''" style="color:rgba(0,0,0,.6);">{{fomatresultsetting(detaildata.result_setting)}}</v-list-item-content>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

              </v-list>
            </v-card-text>
<!-- Login -->
            <v-card-text v-else-if="detaildata.process_type === '4'">
              <v-list two-line subheader>
                 <!-- <v-subheader><v-divider class="mx-4" ></v-divider></v-subheader>
                <v-list-item-icon style="padding-left: 4%">
            <v-icon x-large  style="font-weight: 100"></v-icon>
         </v-list-item-icon> -->
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-account-tie</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.name')}}</v-list-item-title>
          <v-list-item-subtitle v-if="$t('default') === 'th' && detaildata.name_th !==''">{{detaildata.name_th}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="$t('default') === 'en' && detaildata.name_en !==''">{{detaildata.name_en}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>  
         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-cogs</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title >{{$t('adminlog.process_name')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_name !== ''">{{detaildata.process_name}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>           
          <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.process_dtm')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_dtm !== ''">{{formatdatetime(detaildata.process_dtm)}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </v-list>
            </v-card-text>
<!-- get personal detail log  -->
            <v-card-text v-else-if="detaildata.process_type === '44'">
              <v-list two-line subheader>
           
         <!-- <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-account-tie</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.name')}}</v-list-item-title>
          <v-list-item-subtitle v-if="$t('default') === 'th' && detaildata.name_th !==''">{{detaildata.name_th}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="$t('default') === 'en' && detaildata.name_en !==''">{{detaildata.name_en}}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>   -->

          <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-file-edit</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title >{{$t('adminlog.file_folder_name')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.name !== ''">{{detaildata.name}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>{{$t('adminlog.no_name')}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

         <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-cogs</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title >{{$t('adminlog.process_name')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_name !== ''">{{detaildata.process_name}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

       <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-file-find-outline</v-icon>
            <!-- <v-icon v-if="detaildata.data_type == folder">mdi-folder</v-icon> -->
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title >{{$t('adminlog.type')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.data_type !== ''">{{detaildata.data_type}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>  

      <v-list-item>
           <v-list-item-icon>
            <v-icon>mdi-folder-text</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title >{{$t('adminlog.oldfoldername')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.old_foldername !== ''">{{detaildata.old_foldername}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>  

          <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.process_dtm')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.process_dtm !== ''">{{formatdatetime(detaildata.process_dtm)}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </v-list>
  </v-card-text>
<!-- inbox -->
            <v-card-text v-else-if="detaildata.process_type === '2'">
              <v-list two-line subheader>
                 <!-- <v-subheader><v-divider class="mx-4" ></v-divider></v-subheader>
                <v-list-item-icon style="padding-left: 4%">
            <v-icon x-large  style="font-weight: 100"></v-icon>
         </v-list-item-icon> -->
         <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-inbox-arrow-down</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.filenamein')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.filename_inbox !== ''">{{detaildata.filename_inbox}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>  
         <v-list-item>
            <v-list-item-icon>
            <v-icon>mdi-inbox-arrow-up</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.filenameout')}}</v-list-item-title>
          <v-list-item-subtitle v-if="detaildata.filename_outbox !== ''">{{detaildata.filename_outbox}}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>-</v-list-item-subtitle>
        </v-list-item-content>
        
      </v-list-item>           
          <v-list-item>
             <v-list-item-icon>
            <v-icon>device_hub</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('adminlog.newrelation')}}</v-list-item-title>
          <v-list-item-subtitle 
             v-for="item in detaildata.new_relation"
             :key="item"
             
             class="pl-2"
             >     
                <v-list-item-subtitle v-if="item.filename_outbox"  v-text="'-'+item.filename_outbox"></v-list-item-subtitle>
                <v-list-item-subtitle v-else v-text="'-'" >-</v-list-item-subtitle>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
         <v-list-item-icon>
            <v-icon>device_hub</v-icon>
          </v-list-item-icon>
        <v-list-item-content >
          <v-list-item-title>{{$t('adminlog.oldrelation')}}</v-list-item-title>
          <v-list-item-subtitle
           v-for="item in detaildata.old_relation"
             :key="item" 
             class="pl-2"
             >
                <v-list-item-subtitle  v-if="item.filename_outbox" v-text="'-'+item.filename_outbox"></v-list-item-subtitle>
                <v-list-item-subtitle v-else v-text="'-'" >-</v-list-item-subtitle>
               
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </v-list>
            </v-card-text>
<!-- editonline -->
            <v-card-text v-else-if="detaildata.process_type === '6'">
               <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-file-document</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{$t('adminlog.filename')}}</v-list-item-title>
                    <v-list-item-subtitle v-if="detaildata.current !== ''">{{detaildata.current}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                  </v-list-item-content>      
                </v-list-item>  
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-folder</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{$t('adminlog.foldername')}}</v-list-item-title>
                    <v-list-item-subtitle v-if="detaildata.current_foldername !== ''">{{detaildata.current_foldername}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                  </v-list-item-content>       
                </v-list-item>           
                <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-cogs</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title >{{$t('adminlog.process_name')}}</v-list-item-title>
                    <v-list-item-subtitle v-if="$t('default') === 'th' && detaildata.process_name !== ''">{{detaildata.process_name}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="$t('default') === 'en' && detaildata.process_name_eng !== ''">{{detaildata.process_name_eng}}</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                  </v-list-item-content>
                  
                </v-list-item> 
                <v-list-item>
                      <v-list-item-icon>
                      <v-icon>mdi-calendar-text</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{$t('adminlog.process_dtm')}}</v-list-item-title>
                    <v-list-item-subtitle v-if="detaildata.process_dtm !== ''">{{formatdatetime(detaildata.process_dtm)}}</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                      <v-icon>mdi-email</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content >
                    <v-list-item-title>{{$t('adminlog.email')}}</v-list-item-title>
                    <v-list-item-subtitle v-if="detaildata.email !== ''">{{detaildata.email}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                </v-list>
             </v-card-text>
             <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" :dark="color.darkTheme" @click="$emit('closedetaillog')">{{$t('changeName.close')}}</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    </v-row> 
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'


const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
     mixins: [validationMixin],
   validations: {
      filename: { required },
    },
    props: ['show','detaildata'],
    data: function() {
      return {
        test:1,
      }
    },
    computed: {
      ...mapState(['username', 'authorize','account_active','color','role_level']),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
        shower :{
            get() {
                if(this.show === true){
                  console.log(this.detaildata);
                  // if(this.test === 1){
                  //  console.log(this.detaildata);
                   
                  // }
                  this.test = 0;
                }
                return this.show
            },
            set (value) {
                if (!value) {
                 this.selected=[];
                }
            }
        },
        colorProgress(){
      return 'color:'+this.color.theme+';'
    },
  },
  methods:{
    formatdatetime(_datetime) {
      // console.log(this.rootfile);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    fomatresultsetting_permission(parameter){
      console.log(parameter);
      
      let r_2
      let r_1
      let r_3
      let r_4
 let r_5
      if(parameter.detete === "True"){
         r_1 = "Delete : open "
      }else{
 r_1 = "Delete : close "
      }
      if(parameter.edit === "True"){
         r_2 = "Edit : open "
      }else{
 r_2 = "Edit : close "
      }
      if(parameter.download === "True"){
         r_3 = "Download : open "
      }else{
 r_3 = "Download : close "
      }
      if(parameter.view_only === "True"){
         r_4 = "View : open "
      }else{
 r_4 = "View : close "
      }
      if(parameter.upload === "True"){
         r_5 = "Upload : open "
      }else{
 r_5 = "Upload : close "
      }
      let result = r_1+r_2+r_3+r_4+r_5;
      return result
    },
  fomatresultsetting(parameter){
    console.log(parameter);
    let str = parameter.search("my_drive");
    //console.log(str);
    let result;
    if(str === -1){
      result = parameter;
    }else{
       let a = parameter.substring (2,10);
       let b = this.formatresult(parameter.substring (14,15));
       let c = parameter.substring (19,25);
       let d = this.formatresult(parameter.substring (29,30));
       let e = parameter.substring (34,39);
       let f = this.formatresult(parameter.substring (43,44));
       let g = parameter.substring (48,54);
       let h = this.formatresult(parameter.substring (58,59));
       let i = parameter.substring (63,68);
       let j = this.formatresult(parameter.substring (73,74));
       let k = parameter.substring (78,83);
       let l = this.formatresult(parameter.substring (87,88));

       result = a+" = "+b+" "+c+" = "+d+" "+e+" = "+f+" "+g+" = "+h+" "+i+" = "+j+" "+k+" = "+l
    }
    return result
  },
  formatresult(parameter){
    let result;
    if(parameter === "Y"){
      result = "Open"
    }else {
      result = "Close"
    }
    return result
  },
  
      formatdatetime(_datetime) {
      if(_datetime === "" || _datetime === null || _datetime === undefined){
        return "-";
      }else{     
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
  }
}
</script>
<style>

</style>